<template>
  <div>
    <v-card flat height="100vh">
      <div
        style="background-color: #d31145;
          width: 100%; 
          height: 350px; 
          border-radius: 0px 0px 35px 35px; 
          padding-left: 20px; 
          padding-right: 20px;">
        <div 
          class="title font-weight-bold text-center white--text pt-3 mb-12">
          Login
        </div>

        <div class="text-center pa-6">
          <div class="text-center white--text">
            <p class="text-h5 font-weight-bold mb-2">
              Solusi Dagang Anda
            </p>
            
            <div class="body-1">
              Kelola bisnis Anda dengan mudah dan rasakan serunya berdagang tanpa ribet.
            </div>
          </div>
        </div>
      </div>

      <v-card-text class="d-flex align-center justify-center">
        <div class="text-center pa-12" 
          style="background-color: white;
            border-radius: 50%;
            position: absolute;
            z-index: 9">
          <v-img
            :src="require('@/assets/images/soda.png')"
            width="100" 
            alt="sodapos">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-card-text>

      <v-card-text class="py-0">
        <div class="body-1 font-weight-light text-center my-4 py-8 px-12">
          <p class="mt-12">
            Kelola bisnis dan pantau aktivitas penjualan kapan pun dan di mana pun dengan 
          </p>

          <span class="font-weight-bold"> 
            SODA POS 
          </span>
        </div>
      </v-card-text>
      
      <v-card-text
        class="pa-6"
        style="
          position: absolute; 
          bottom: 0; 
          width: 100%;">
        <v-btn 
          @click="LoginGoogle" 
          :disabled="process.run" 
          block 
          rounded 
          large
          outlined 
          color="#d31145" 
          class="d-flex justify-start text-capitalize">
          <v-img
            :src="require('@/assets/images/icon_google.png')" 
            width="20" 
            alt="sodapos">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <span class="body-1"
            style="display:flex;justify-content:center;width:100%">
            Login
          </span>
        </v-btn>

        <v-btn 
          @click="LoginGoogle" 
          :disabled="process.run" 
          block 
          large
          rounded 
          elevation="0" 
          color="#d31145" 
          class="d-flex white--text justify-start mt-5 text-capitalize">
          <v-img
            :src="require('@/assets/images/icon_google_white.png')"
            width="20" 
            alt="sodapos">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <span class="body-1"
            style="display:flex;justify-content:center;width:100%">
            Daftar & Buat Toko
          </span>
        </v-btn>

        <div class="body-2 text-center mt-6">
          SODAPOS v1.0.1
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  export default {
    data: () => ({
      process: {
        run: false
      }
    }),
    watch: {

    },
    computed: {

    },
    created() {

    },
    mounted(){

    },
    methods: {
      LoginGoogle() {
        this.$store.state.overlay.state = true
        let provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
          let token_id = result.credential.idToken, 
              data_user = result.additionalUserInfo
          localStorage.setItem('token_id',token_id);
          this.LoginSSO(data_user, token_id)
        }).catch((error) => {
          this.$store.state.overlay.state = false
        })
      },
      async LoginSSO(data_user, token_id) {
        this.process.run = true

        const data_profile = {
          name: data_user.profile.name,
          email: data_user.profile.email,
          image_url: data_user.profile.picture,
          platform: 'web',
          token_auth: token_id,
          fcm_auth: localStorage.getItem('TOKEN_FCM')
        }

        let response = await this.$post(`auth/auth`, data_profile)
        if(response.status === 200){
          localStorage.setItem('user', JSON.stringify(response.results))

          window.location = `${process.env.BASE_URL}`
        }

        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = response.message

        this.$store.state.overlay.state = false
        this.process.run = false
      }
    }
  }
</script>
